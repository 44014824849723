<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-02-03 14:23:06
-->
<template>
  <el-dialog :title="$t('w_0179')" :visible.sync="addShow" center="" width="25%" :close-on-click-modal="false"
    :before-close="handleCloseBank">
    <div class="add_pay">
      <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="auto">
        <el-form-item :label="$t('MY_WAL_24') + ':'" prop="bankNo">
          <el-input v-model="form.bankNo" :placeholder="$t('MY_WAL_24')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('PER_DA_14') + ':'" prop="name">
          <el-input v-model="form.name" :placeholder="$t('MY_WAL_33')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('ENU_I_T_1') + ':'" prop="idCard">
          <el-input v-model="form.idCard" :placeholder="$t('w_0183')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('w_0180') + ':'" prop="phone">
          <el-input v-model="form.phone" maxlength="20" oninput="value=value.replace(/[^\d]/g,'')"
            :placeholder="$t('w_0184')"></el-input>
        </el-form-item>
        <el-form-item label="有效期:">
          <el-input v-model="form.validdate" :placeholder="$t('S_C_70')"></el-input>
        </el-form-item>
        <el-form-item label="安全码:">
          <el-input v-model="form.cvv2" :placeholder="$t('S_C_70')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('w_0043') + ':'" prop="smsCode">
          <el-input v-model="form.smsCode" :placeholder="$t('w_0187')"></el-input>
          <div class="hqyzm1" :class="isSend ? 'hui' : ''" @click="submitForm('form')">{{ getCode }}</div>
        </el-form-item>
      </el-form>
      <div class="tips" v-if="isTl=='hf'">
        <p>1、目前汇付支付支持绑定信用卡、储蓄卡</p>
        <p>2、当绑定信用卡时，“安全码”、“有效期”两栏为必填项</p>
        <p>3、安全码为信用卡背面签名栏后3位数字；</p>
        <p>4、信用卡有效期格式不加/，例信用卡有效期10/24，请输入：1024</p>
      </div>
    </div>
    <div class="surebtn1">
      <div class="btn" @click="bindBank">{{ this.$t('w_0035') }}</div>
      <div class="btn hh" @click="handleCloseBank">{{ $t('ENU_P_TYPE0') }}</div>
    </div>
  </el-dialog>
</template>

<script>
import * as pay from '@/api/pay.js'
export default {
  data() {
    return {
      addShow: false,
      form: {},
      isSend: false,
      beginTime: 60,
      getCode: this.$t('w_0055'),
      timer: '',
      rules: {
        bankNo: [
          { required: true, message: this.$t('w_0188'), trigger: 'blur' },
        ],
        name: [{ required: true, message: this.$t('w_0189'), trigger: 'blur' }],
        idCard: [
          { required: true, message: this.$t('w_0190'), trigger: 'blur' },
        ],
        // smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        phone: [
          {
            required: true,
            message: this.$t('w_0191'),
            trigger: 'blur',
          },
          { min: 11, message: this.$t('w_0134'), trigger: 'blur' },
        ],
      },
      isTl: '',
    }
  },
  props: {
    isAdd: {
      type: Boolean,
      default: false,
    },
    jdTl: {
      type: String,
      default: '',
    },
  },
  watch: {
    isAdd(val) {
      this.addShow = val
    },
    jdTl(val) {
      this.isTl = val
    },
  },
  methods: {
    handleCloseBank() {
      this.$emit('closeBind', 0)
      this.$refs.form.resetFields()
    },

    areBothValuesSet(val1, val2) {
      return (
        ((val1 === null || val1 === undefined || val1 === '') &&
          (val2 === null || val2 === undefined || val2 === '')) ||
        (val1 !== null &&
          val1 !== undefined &&
          val1 !== '' &&
          val2 !== null &&
          val2 !== undefined &&
          val2 !== '')
      )
    },
    // 绑卡
    bindBank() {
      if (!this.bankCode) {
        this.$message({
          message: '验证码错误',
          type: 'warning',
        })
        return
      }
      let that = this
      let url = ''
      if (this.isTl == 'tl') {
        url = pay.bindTlConfirm
      } else if (this.isTl == 'jd') {
        url = pay.bindJdConfirm
      } else if (this.isTl == 'hf') {
        url = pay.bindHfConfirm
      }
      url({
        bindCode: this.bankCode,
        smsCode: this.form.smsCode,
      }).then((res) => {
        this.$message({
          message: '银行卡绑定成功',
          type: 'success',
        })
        this.$refs.form.resetFields()
        setTimeout(() => {
          that.$emit('closeBind', 1)
        }, 1500)
      })
    },
    // 绑卡获取验证码
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (
            !this.areBothValuesSet(this.form.validdate, this.form.cvv2)
          ) {
            this.$message({
              message: '请输入安全码及有效期',
              type: 'warning',
            })
            return
          }
          for (let key in this.form) {
            if (this.form[key] == '') {
              delete this.form[key]
            }
          }
          this.startTime()
          let url = ''
          if (this.isTl == 'tl') {
            url = pay.bindTl
          } else if (this.isTl == 'jd') {
            url = pay.bindJd
          } else if (this.isTl == 'hf') {
            url = pay.bindHf
          }
          url(this.form)
            .then((res) => {
              this.bankCode = res.data
            })
            .catch((err) => {
              this.beginTime = 0
            })
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    startTime() {
      if (this.isSend) return
      this.isSend = true
      this.getCode = this.beginTime + 's后重新发送'
      let that = this
      this.timer = setInterval(() => {
        this.beginTime--
        this.getCode = this.beginTime + 's后重新发送'
        if (this.beginTime < 0) {
          clearInterval(this.timer)
          this.getCode = this.$t('w_0055')
          this.beginTime = 60
          this.isSend = false
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.add_pay {
  padding: 20px;

  ::v-deep .el-form-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); // padding-bottom: 4px;
    margin-bottom: 20px;
  }
}

.hqyzm1 {
  background: #d5251d;
  border-radius: 8px;
  padding: 0 7px;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
}

.hui {
  opacity: 0.5;
}

.add_pay {
  ::v-deep .el-form-item {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-form-item__content {
    flex: 1;
    display: flex;
    margin-left: 0 !important;
  }

  ::v-deep .el-input {
    flex: 1;
    width: auto;
  }

  ::v-deep .el-input__inner {
    border: 0;
  }
}

.surebtn1 {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .btn {
    width: 180px;
    height: 50px;
    background: #d5251d;
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
    cursor: pointer;
  }

  .hh {
    background: #cccccc;
  }
}
.tips{
  font-size: 12px;
  p{
    line-height: 10px;
  }
  color: #d5251d;
}
</style>